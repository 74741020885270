// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image {
  backdrop-filter: blur(11px);
  top: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
.image.active {
  display: none;
  animation: scale-display 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/Components/Ui/Card/style.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,MAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,mEAAA;AACF;AACE;EACE,aAAA;EACA,6BAAA;AACJ","sourcesContent":[".image {\n  backdrop-filter: blur(11px);\n  top: 0;\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;\n\n  &.active {\n    display: none;\n    animation: scale-display 0.3s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
