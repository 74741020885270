// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Calibri/Calibri Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/helvetica-neue-lt-pro-2-cufonfonts/HelveticaNeueLTProBd.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bad+Script&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Calibri";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
  font-family: "HelveticaNeueLT";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
}

textarea::placeholder {
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
}

textarea:-ms-input-placeholder {
  color: rgb(255, 255, 255);
  opacity: 1;
}

textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(255, 255, 255);
  opacity: 1;
}

textarea[disabled] {
  opacity: 1;
  color: rgb(255, 255, 255);
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAIA;EACE,UAAA;EACA,SAAA;EACA,sBAAA;AAAF;;AAGA;EACE,sBAAA;EACA,4CAAA;AAAF;AAEA;EACE,8BAAA;EACA,4CAAA;AAAF;AAGA;EACE,yCAAA;EACA,yBAAA;EACA,UAAA,EAAA,YAAA;AADF;;AAcA;EACE,yBAAA;EACA,UAAA,EAAA,YAAA;AADF;;AAIA;EACE,yBAAA;EACA,UAAA;AADF;;AAIA;EACE,mBAAA;EACA,yBAAA;EACA,UAAA;AADF;;AAIA;EACE,UAAA;EACA,yBAAA;AADF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Bad+Script&display=swap\");\n\n* {\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n}\n\n@font-face {\n  font-family: \"Calibri\";\n  src: url(\"../src/assets/fonts/Calibri/Calibri\\ Light.ttf\");\n}\n@font-face {\n  font-family: \"HelveticaNeueLT\";\n  src: url(\"../src/assets/fonts/helvetica-neue-lt-pro-2-cufonfonts/HelveticaNeueLTProBd.otf\");\n}\n\ninput::placeholder {\n  /* Chrome, Firefox, Opera, Safari 10.1+ */\n  color: rgb(255, 255, 255);\n  opacity: 1; /* Firefox */\n}\n\ninput:-ms-input-placeholder {\n  /* Internet Explorer 10-11 */\n  color: rgb(255, 255, 255);\n}\n\ninput::-ms-input-placeholder {\n  /* Microsoft Edge */\n  color: rgb(255, 255, 255);\n}\n\ntextarea::placeholder {\n  color: rgb(255, 255, 255);\n  opacity: 1; /* Firefox */\n}\n\ntextarea:-ms-input-placeholder {\n  color: rgb(255, 255, 255);\n  opacity: 1;\n}\n\ntextarea::-ms-input-placeholder {\n  /* Microsoft Edge */\n  color: rgb(255, 255, 255);\n  opacity: 1;\n}\n\ntextarea[disabled] {\n  opacity: 1;\n  color: rgb(255, 255, 255);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
