// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg--popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  display: none;
  z-index: 7;
}
.bg--popup.active {
  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  display: block;
  background: rgba(0, 0, 0, 0.6);
}

.popupMessage {
  transform: translateX(150vw);
  transition: all 0.55s;
  opacity: 0;
  z-index: 7;
}
.popupMessage.transform {
  transform: translateX(0);
  opacity: 1;
  top: "50px";
}
.popupMessage-link {
  display: flex;
  justify-content: end;
  font-size: 24px;
  margin-bottom: 46px;
  font-weight: 600;
}
.popupMessage-link.active {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.15);
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Ui/popups/style.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,mEAAA;EACA,aAAA;EACA,UAAA;AADF;AAGE;EACE,mEAAA;EACA,cAAA;EACA,8BAAA;AADJ;;AAKA;EACE,4BAAA;EACA,qBAAA;EACA,UAAA;EACA,UAAA;AAFF;AAIE;EACE,wBAAA;EACA,UAAA;EACA,WAAA;AAFJ;AAKE;EACE,aAAA;EACA,oBAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;AAHJ;AAKI;EACE,mBAAA;EACA,qCAAA;EACA,aAAA;AAHN","sourcesContent":["$transitionTime: \"1\" \".5\", \"2\" \".7\", \"3\" \"1\", \"4\" \"1.2\", \"5\" \"1.5\";\n\n.bg--popup {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;\n  display: none;\n  z-index: 7;\n\n  &.active {\n    animation: scaleUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;\n    display: block;\n    background: rgba(0, 0, 0, 0.6);\n  }\n}\n\n.popupMessage {\n  transform: translateX(150vw);\n  transition: all 0.55s;\n  opacity: 0;\n  z-index: 7;\n\n  &.transform {\n    transform: translateX(0);\n    opacity: 1;\n    top: \"50px\";\n  }\n\n  &-link {\n    display: flex;\n    justify-content: end;\n    font-size: 24px;\n    margin-bottom: 46px;\n    font-weight: 600;\n\n    &.active {\n      border-radius: 10px;\n      background: rgba(255, 255, 255, 0.15);\n      padding: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
