import {
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { CiCalendarDate } from "react-icons/ci";

import { FormEvent, HTMLInputTypeAttribute, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import API from "../../../Api";
import Loading from "../../../Components/Ui/Loading";
import { IVaccination } from "../../../Components/Interface/redux/types/Types";
import { useParams } from "react-router";
import { useActionsUser } from "../../../Hooks/useActions";

const Vaccination = () => {
  const [isLoading, setLoading] = useState(false);
  const { ActionGetUser } = useActionsUser();
  const { id } = useParams<string>();
  const [value, setValue] = useState<IVaccination>({
    vaccine: "",
    vaccine_date: "",
    batch_no: "",
    booster_required: false,
    booster_date: "",
  });
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const onChangeBooster = (required: string) => {
    let booster_required = required === "1";
    let booster_date = booster_required ? value.booster_date : "";
    setValue({
      ...value,
      booster_required,
      booster_date,
    });
  };

  const onsubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await API.post(`users/vaccination/create/`, value);

      setLoading(false);
      onClose();
      ActionGetUser(id);
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <>
      <Flex
        onClick={onOpen}
        fontSize="13px"
        fontFamily="inter"
        fontWeight="300"
        rounded="7px"
        alignItems="center"
        w="100%"
        justifyContent="center"
        color="#0B6CFF"
        gap="1"
        mt="1"
        cursor="pointer"
        _active={{ opacity: ".8" }}
      >
        <Text fontSize="22px" mb="2px">
          +
        </Text>
        {t("Vaccination.buttons.addMore")}
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom"
        autoFocus={false}
      >
        <DrawerOverlay />
        <DrawerContent borderTopRadius="12px" bg="#272727" pt="6" pb="10">
          <Container>
            <Text
              color="#FFFFFF"
              fontSize="13px"
              fontWeight="700"
              fontFamily="inter"
              mb="6"
              textAlign="center"
              textTransform="uppercase"
            >
              {t("Vaccination.title")}
            </Text>
            <form onSubmit={onsubmit}>
              <Box>
                <Text
                  color="gray"
                  fontSize="13px"
                  fontWeight="700"
                  fontFamily="inter"
                  mb="3px"
                  textAlign="center"
                  textTransform="uppercase"
                >
                  <Trans>Vaccination.name.title</Trans>
                </Text>
                <Flex gap="1">
                  <InputComponent
                    handleChange={handleChange}
                    name="vaccine"
                    value={value.vaccine}
                    isRequired={true}
                    type="text"
                    placeholder={t("Vaccination.name.placeholder")}
                  />
                  <Box w="40%">
                    <DateInput
                      handleChange={handleChange}
                      name="vaccine_date"
                      value={value.vaccine_date}
                      placeholder={t("Vaccination.date.placeholder")}
                    />
                  </Box>
                </Flex>
              </Box>

              <InputComponent
                handleChange={handleChange}
                name="batch_no"
                value={value.batch_no}
                isRequired={true}
                type="tel"
                placeholder={t("Vaccination.batchNo.placeholder")}
                title={t("Vaccination.batchNo.title") || ""}
              />
              <Box mb="3">
                <Text
                  color="gray"
                  fontSize="13px"
                  fontWeight="700"
                  fontFamily="inter"
                  mb="3px"
                  textAlign="center"
                  textTransform="uppercase"
                >
                  {t("Vaccination.required.title")}
                </Text>
                <Flex
                  mt="2"
                  gap="1"
                  justifyContent="center"
                  alignItems="center"
                  overflow="hidden"
                >
                  <RadioGroup
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    colorScheme="gray"
                    mb="1"
                    w="100%"
                    onChange={onChangeBooster}
                    value={value.booster_required ? "1" : "0"}
                  >
                    <Radio
                      value="1"
                      _focus={{ boxShadow: "none" }}
                      isRequired={true}
                    >
                      <Text fontSize="16px" color="#ffffff">
                        {t("Vaccination.required.yes")}
                      </Text>
                    </Radio>
                    <Divider
                      mx="4"
                      h="20px"
                      bg="#ffffff"
                      opacity=".2"
                      w="1px"
                    />
                    <Radio
                      value="0"
                      _focus={{ boxShadow: "none" }}
                      isRequired={true}
                    >
                      <Text fontSize="16px" color="#ffffff">
                        {t("Vaccination.required.no")}
                      </Text>
                    </Radio>
                  </RadioGroup>
                  <Box
                    w="40%"
                    mr={value.booster_required ? "0" : "-41%"}
                    transition=".5s"
                  >
                    <DateInput
                      handleChange={handleChange}
                      name="booster_date"
                      value={value.booster_date}
                      placeholder={t("Vaccination.date.placeholder")}
                    />
                  </Box>
                </Flex>
              </Box>

              {isLoading ? (
                <Box mt="50px">
                  <Loading size="40px" />
                </Box>
              ) : (
                <Button
                  type="submit"
                  mt="41px"
                  bg="#0B6CFF"
                  fontSize="16px"
                  fontFamily="inter"
                  rounded="7px"
                  h="37px"
                  w="100%"
                  color="white"
                  onClick={onOpen}
                >
                  {t("Vaccination.buttons.add")}
                </Button>
              )}
            </form>
          </Container>
        </DrawerContent>
      </Drawer>
    </>
  );
};

interface InputComponentProps {
  value: string;
  placeholder: string;
  type: HTMLInputTypeAttribute;
  name: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
  isRequired?: boolean;
}
function InputComponent(props: InputComponentProps) {
  return (
    <>
      {!!props.title && (
        <Text
          color="gray"
          fontSize="13px"
          fontWeight="700"
          fontFamily="inter"
          mb="3px"
          textAlign="center"
          textTransform="uppercase"
        >
          <Trans>{props.title}</Trans>
        </Text>
      )}
      <Input
        variant="none"
        onChange={props.handleChange}
        h="39px"
        bg="#141414"
        name={props.name}
        placeholder={props.placeholder}
        color="#FFFFFF"
        value={props.value}
        type={props.type}
        fontSize="16px"
        lineHeight="14.5px"
        _placeholder={{ color: "#595959" }}
        letterSpacing="1px"
        mb="3"
        isRequired={props.isRequired}
      />
    </>
  );
}

interface DateInputProps {
  value: string;
  name: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}
function DateInput(props: DateInputProps) {
  return (
    <Box w="100%" position="relative" h="39px">
      <Input
        variant="none"
        onChange={props.handleChange}
        h="100%"
        bg="#141414"
        name={props.name}
        placeholder={props.placeholder}
        color="#FFFFFF"
        value={props.value}
        type="date"
        fontSize="16px"
        lineHeight="14.5px"
        _placeholder={{ color: "#595959" }}
        letterSpacing="1px"
      />
      {!props.value && (
        <Flex
          position="absolute"
          left="0"
          top="0"
          zIndex="1"
          rounded="md"
          color="#aaa"
          w="100%"
          h="100%"
          bg="#141414"
          pointerEvents="none"
          fontSize="16px"
          justifyContent="space-around"
          alignItems="center"
          px="2"
        >
          <CiCalendarDate opacity="0" />
          {props.placeholder}
          <CiCalendarDate color="#aaa" />
        </Flex>
      )}
    </Box>
  );
}

export default Vaccination;
