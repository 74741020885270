import { Box, Divider, Input, InputProps, Stack, Text } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { ILocationState } from "../../../Components/Interface/redux/types/Types";

interface IInputItem {
  name: string;
  placeholder: string;
  value: string | undefined;
  bottomPadding?: boolean;
}

interface Props {
  isActiveEdit: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  locationValue?: ILocationState | null;
}
export function LocationAkte({
  handleChange,
  isActiveEdit,
  locationValue,
}: Props) {
  const { t } = useTranslation();
  const InputList: IInputItem[] = [
    {
      name: "street",
      placeholder: t("LocationFields.street"),
      value: locationValue?.street,
    },
    {
      name: "village",
      placeholder: t("LocationFields.village"),
      value: locationValue?.village,
    },
    {
      name: "parish",
      placeholder: t("LocationFields.parish"),
      value: locationValue?.parish,
      bottomPadding: true,
    },
    {
      name: "district",
      placeholder: t("LocationFields.district"),
      value: locationValue?.district,
    },
    {
      name: "city",
      placeholder: t("LocationFields.city"),
      value: locationValue?.city,
    },
    {
      name: "region",
      placeholder: t("LocationFields.region"),
      value: locationValue?.region,
      bottomPadding: true,
    },
    {
      name: "country",
      placeholder: t("LocationFields.country"),
      value: locationValue?.country,
    },
    {
      name: "nearest",
      placeholder: t("LocationFields.nearest"),
      value: locationValue?.nearest,
    },
  ];

  return (
    <Box>
      <Text
        mx="13px"
        color="gray"
        fontSize="13px"
        fontWeight="700"
        fontFamily="inter"
        mb="3px"
        textAlign="center"
        textTransform="uppercase"
      >
        <Trans>location</Trans>
      </Text>
      <Stack
        bgColor={isActiveEdit ? "#1c1c1e" : "transparent"}
        py="2"
        mb="12"
        mt="8"
      >
        {InputList.filter((item) =>
          !isActiveEdit ? item.value : item.placeholder
        ).map((el, idx, arr) => (
          <LocationInput
            key={idx}
            {...el}
            onChange={handleChange}
            withoutDivider={!idx || arr[idx - 1].bottomPadding}
            isActiveEdit={isActiveEdit}
          />
        ))}
      </Stack>
    </Box>
  );
}

interface LocationInputProps extends InputProps {
  withoutDivider?: boolean;
  isActiveEdit: boolean;
  bottomPadding?: boolean;
}
function LocationInput({
  withoutDivider,
  isActiveEdit,
  placeholder,
  bottomPadding,
  ...props
}: LocationInputProps) {
  return (
    <>
      <Stack px="4">
        {!withoutDivider && isActiveEdit && <DividerInputs />}
        <Input
          placeholder={isActiveEdit ? placeholder : ""}
          fontFamily="Helvetica"
          variant="none"
          padding="3"
          h="36px"
          fontWeight="400"
          fontSize="16px"
          border="none"
          rounded="0"
          outline="none"
          px="0"
          bgColor="transparent"
          color="#fff"
          _placeholder={{ color: "#636366" }}
          _focus={{ outline: "none" }}
          boxSizing="border-box"
          isReadOnly={!isActiveEdit || props.isReadOnly}
          w="100%"
          {...props}
        />
      </Stack>
      {isActiveEdit && bottomPadding && <Box h="6" bg="black" />}
    </>
  );
}

function DividerInputs() {
  return (
    <Divider bgColor={"#2c2c2e"} border="none" margin="0" h="1px" w="100%" />
  );
}
