// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lang-select {
  width: 100%;
  border-radius: 5px;
  padding-left: 5px;
  font-family: "Inter", sans-serif;
}

.lang-select > option {
  font-weight: 300;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  color: rgb(31, 31, 31);
  background-color: rgb(247, 247, 247);
  background-image: none;
  height: 50px;
  padding: 15px;
  border: 1px solid rgb(41, 18, 18);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Ui/Select/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,iBAAiB;EACjB,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gCAAgC;EAChC,sBAAsB;EACtB,oCAAoC;EACpC,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,iCAAiC;AACnC","sourcesContent":[".lang-select {\n  width: 100%;\n  border-radius: 5px;\n  padding-left: 5px;\n  font-family: \"Inter\", sans-serif;\n}\n\n.lang-select > option {\n  font-weight: 300;\n  font-size: 16px;\n  font-family: \"Inter\", sans-serif;\n  color: rgb(31, 31, 31);\n  background-color: rgb(247, 247, 247);\n  background-image: none;\n  height: 50px;\n  padding: 15px;\n  border: 1px solid rgb(41, 18, 18);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
