import { Box, Flex, Stack, Text, useOutsideClick } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import {
  InterfaceUserTypes,
  IVaccination,
} from "../../../Components/Interface/redux/types/Types";
import { FaMinus } from "react-icons/fa6";
import { useRef, useState } from "react";
import { FaTrash } from "react-icons/fa";
import API from "../../../Api";
import { useAppDispatch, useAppSelector } from "../../../Hooks/Hooks";
import Loading from "../../../Components/Ui/Loading";
import moment from "moment";

const VaccinationCard = ({
  el,
  isFirst,
}: {
  el: IVaccination;
  isFirst: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userReducer);
  const [isLoading, setLoading] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  const requiredText = el.booster_required
    ? t("Vaccination.required.yes")
    : t("Vaccination.required.no");

  useOutsideClick({
    ref: ref,
    handler: () => setDeleteActive(false),
  });

  const onRemove = async () => {
    setLoading(true);
    const vaccinations =
      user.vaccinations?.filter((item) => item.id !== el.id) || [];
    try {
      await API.delete(`users/vaccination/destroy/${el.id}/`);

      dispatch({
        type: InterfaceUserTypes.USERS,
        payload: { ...user, vaccinations },
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <Flex position="relative" overflow="hidden">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        px="13px"
        fontFamily="Helvetica"
      >
        <Flex
          onClick={() => setDeleteActive(true)}
          justifyContent="center"
          alignItems="center"
          rounded="50%"
          bg="#AB0000"
          color="#D9D9D9"
          w="20px"
          h="20px"
          cursor="pointer"
          _active={{ opacity: ".9" }}
        >
          <FaMinus fontSize="13px" />
        </Flex>
        <Stack
          spacing="13px"
          borderTop={!isFirst ? "0.1px solid rgba(69, 69, 69, 0.28)" : "none"}
          py="4"
        >
          <CardItem
            title={"Vaccination.name.placeholder"}
            value={el.vaccine}
            date={el.vaccine_date}
          />
          <CardItem
            title={"Vaccination.batchNo.placeholder"}
            value={el.batch_no}
          />
          <CardItem
            title={"Vaccination.required.placeholder"}
            date={el.booster_date}
            value={requiredText}
          />
        </Stack>
        <Box w="20px" h="20px" />
      </Flex>
      <Flex
        onClick={onRemove}
        alignItems="center"
        minH="100%"
        px="3"
        ref={ref}
        mr={deleteActive ? "0" : "-100px"}
        transition=".5s"
        cursor="pointer"
        shadow="md"
        bg="#F44336"
        _active={{ opacity: ".8" }}
        w="40px"
      >
        {isLoading ? <Loading size="16px" /> : <FaTrash />}
      </Flex>
    </Flex>
  );
};

function CardItem(props: { title: string; value: string; date?: string }) {
  const date = props.date ? moment(props.date).format("DD-MM-YYYY") : null;
  return (
    <Flex mx="auto" flexDirection="column" alignItems="center" gap="6px">
      <Text
        color="#595959"
        fontWeight="400"
        fontSize="12px"
        lineHeight="14.5px"
        letterSpacing="1.2px"
        whiteSpace="nowrap"
      >
        <Trans>{props.title}</Trans>
      </Text>
      <Stack
        alignItems="center"
        color="#FFFFFF"
        fontWeight="400"
        fontSize="16px"
        lineHeight="19.36px"
        letterSpacing="1.2px"
      >
        <Text>
          <Trans>{props.value}</Trans>
        </Text>
        {!!date && (
          <Text>
            <Trans>{date}</Trans>
          </Text>
        )}
      </Stack>
    </Flex>
  );
}
export default VaccinationCard;
