import { Select, Text } from "@chakra-ui/react";
import React from "react";

interface ProfileSelectProps {
  title: string;
  isActive: boolean;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  list: string[];
  name: string;
}
const ProfileSelect2 = ({
  handleChange,
  isActive,
  title,
  value,
  list,
  name,
}: ProfileSelectProps) => {
  return (
    <>
      {!isActive ? (
        <Text
          fontSize="17px"
          color="#FFFFFF"
          lineHeight="25px"
          mb="4"
          fontWeight="400"
        >
          {value}
        </Text>
      ) : (
        <Select
          name={name}
          variant="none"
          disabled={!isActive}
          onChange={handleChange}
          placeholder={title}
          value={value}
          fontSize="17px"
          textAlign="center"
          lineHeight="25px"
          bg="transparent"
          fontWeight="400"
          color={"#FFFFFF"}
        >
          {list.map((el, idx) => (
            <option value={el} key={idx} className="select-option">
              {el}
            </option>
          ))}
        </Select>
      )}
    </>
  );
};

export default ProfileSelect2;
