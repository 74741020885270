// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-backdrop {
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
}

.modal-content-wrapper {
    position: fixed;
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    bottom: 60px;
    z-index: 5;
}

.modal-content {
    margin: 0 18px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Interface/popup/style.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,eAAe;IACf,YAAY;IACZ,aAAa;IACb,MAAM;IACN,OAAO;IACP,SAAS;IACT,QAAQ;IACR,UAAU;AACd;;AAEA;IACI,eAAe;IACf,WAAW;IACX,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,oBAAoB;IACpB,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".modal-backdrop {\n    background: rgba(0, 0, 0, 0.6);\n    position: fixed;\n    width: 100vw;\n    height: 100vh;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    z-index: 5;\n}\n\n.modal-content-wrapper {\n    position: fixed;\n    width: 100%;\n    height: 300px;\n    display: flex;\n    flex-direction: column;\n    justify-content: end;\n    bottom: 60px;\n    z-index: 5;\n}\n\n.modal-content {\n    margin: 0 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
