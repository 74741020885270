// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes identifier {
  0% {
    bottom: 1%;
    opacity: 0.4;
  }
  25% {
    bottom: 3%;
    opacity: 1;
  }
  75% {
    bottom: 3%;
    opacity: 1;
  }
  100% {
    bottom: 7%;
    opacity: 0.1;
  }
}
.welcome-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.welcome-container.visible {
  opacity: 1;
}

.welcome-message {
  position: absolute;
  left: 0;
  right: 0;
  animation: identifier 3.7s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/Components/WelcomePage/style.scss"],"names":[],"mappings":"AAAA;EACE;IACE,UAAA;IACA,YAAA;EACF;EACA;IACE,UAAA;IACA,UAAA;EACF;EACA;IACE,UAAA;IACA,UAAA;EACF;EACA;IACE,UAAA;IACA,YAAA;EACF;AACF;AAEA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,UAAA;EACA,oCAAA;AAAF;AAEE;EACE,UAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,OAAA;EACA,QAAA;EACA,sCAAA;AADF","sourcesContent":["@keyframes identifier {\n  0% {\n    bottom: 1%;\n    opacity: 0.4;\n  }\n  25% {\n    bottom: 3%;\n    opacity: 1;\n  }\n  75% {\n    bottom: 3%;\n    opacity: 1;\n  }\n  100% {\n    bottom: 7%;\n    opacity: 0.1;\n  }\n}\n\n.welcome-container {\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #fff;\n  opacity: 0;\n  transition: opacity 0.5s ease-in-out;\n\n  &.visible {\n    opacity: 1;\n  }\n}\n\n.welcome-message {\n  position: absolute;\n  left: 0;\n  right: 0;\n  animation: identifier 3.7s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
